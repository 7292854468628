/**
 * Styles
 */
import './style.scss';

/**
 * External Dependencies
 */
import React, { Component } from 'react';

/**
 * Internal Dependencies
 */
import AsyncComponent from '../../components/async-component';

/**
 * Component
 */
class AuthSignInPage extends Component {
    render() {
        return (
            <div className="">
                    <AsyncComponent component={ () => import( './content' ) } />
                
            </div>
        );
    }
}

export default AuthSignInPage;
